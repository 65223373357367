<template>
    <div>
        <section v-if="!isIncluded" class="page-banner" style="background-image:url(/images/background/bg-banner-1.jpg);">
            <div class="top-pattern-layer"></div>

            <div class="banner-inner">
                <div class="auto-container">
                    <div class="inner-container clearfix">
                        <h1>List of The Dragons</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="games-section games-page-section">
            <div class="auto-container">
                <ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-6780964513098033"
                data-ad-slot="4938165242"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>

                <div class="default-form">
                    <div class="row clearfix">
                        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                            <input type="text" v-model="searchName" name="name" placeholder="Dragon Name" autocomplete="off">
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 form-group" id="dragon-rarities">
                            <div v-for="type in rarities" :key="type" class="dragon-rarity" @click="searchRarity(type)" v-bind:class="{'selected_ele': type == rarity}">
                                <img :src="`/images/dragons/type/${type}.png`" :alt="`Dragon Rarity ${type}`" width="45">
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group" id="dragon-elements">
                            <div v-for="(name, ele) in elements" :key="ele" class="dragon-element" @click="searchElement(ele)" v-bind:class="{'selected_ele': searchElements.indexOf(ele) > -1}">
                                <img :src="`/images/dragons/elements/ic-${name}-flag.png`" :alt="`Dragon Element ${ele}`" width="30">
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <h4>Showing {{ (processedDragons.length >= show) ? show : processedDragons.length }}/{{ processedDragons.length }} dragon(s)</h4>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-12 form-group">
                            <select name="show" id="dragon_show" v-model="show">
                                <option value="9">Show 9 dragons</option>
                                <option value="15">Show 15 dragons</option>
                                <option value="21">Show 21 dragons</option>
                                <option value="30">Show 30 dragons</option>
                            </select>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row clearfix">
                    <div class="game-block col-lg-4 col-md-6 col-sm-12" v-for="dragon in list" :key="dragon.id">
                        <div class="inner-box">
                            <div class="image-box">
                                <figure class="image">
                                    <router-link :to="`/dragons/${dragon.urlName.toLowerCase()}`" class="dragon-avatar">
                                        <img :src="getAvatar(dragon, i)" v-for="i in 4" :key="i" :alt="dragon.name" :title="dragon.name" @error="getErrorImg">
                                    </router-link>
                                </figure>
                                <div class="dragon-type">
                                    <img :src="`/images/dragons/type/${dragon.rarity}.png`" alt="Dragon Type" width="50">
                                </div>
                            </div>
                            <div class="lower-content">
                                <div class="rating">
                                    <span v-for="ele in dragon.dragonAttribute" :key="ele">
                                        <img :src="`/images/dragons/elements/${ele.replace('/Content/Images/DragonType/', '')}`" alt="Dragon Element" width="40">
                                    </span>
                                </div>
                                <div class="dragon-ranked" v-if="isRanked">
                                    <h3>{{ rankedDragons.indexOf(dragon) + 1 }}</h3>
                                </div>
                                <div class="dragon-ranked" v-if="isTop">
                                    <h3>{{ processedDragons.indexOf(dragon) + 1 }}</h3>
                                </div>
                                <h3>
                                    <router-link :to="`/dragons/${dragon.urlName.toLowerCase()}`">{{ dragon.name }}</router-link>
                                </h3>                                
                                <div class="post-info">
                                    <ul class="clearfix">
                                        <li><a href="javascript:void(0)"><span class="icon flaticon-time"></span> {{ convertSecondsToTimeString(dragon.hatchingTime) }}</a></li>
                                        <li><a href="javascript:void(0)"><span class="icon flaticon-time"></span> {{ convertSecondsToTimeString(dragon.breedingTime) }}</a></li>
                                        <li><a href="javascript:void(0)" class="dragon-damage"><span class="icon flaticon-rocket-launch"></span> {{ dragon.maxDamage }}</a></li>
                                        <li><a href="javascript:void(0)" class="dragon-price"><span class="icon flaticon-coin"></span> {{ dragon.priceGem }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pagination-box text-center">
                    <ul class="styled-pagination">
                        <li>
                            <a href="javascript:void(0)" @click="paging(1)">
                                <span class="fa fa-angle-left"></span>
                            </a>
                        </li>
                        <li v-for="p in page + 4" :key="p" v-show="p > page - 5 && p <= totalPage">
                            <a v-if="p > page - 5 && p <= totalPage" href="javascript:void(0)" :class="{ 'active': page == p }" @click="paging(p)">{{ p }}</a>
                        </li>                        
                        <li>
                            <a href="javascript:void(0)" @click="paging(totalPage)">
                                <span class="fa fa-angle-right"></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>

<script>    
    export default {
        name: 'Dragons',
        components: {

        },
        props: {
            dragons: Array,
            elements: Object,
            isTop: Boolean,
            isRanked: Boolean,
            isIncluded: Boolean
        },
        created() {
            this.processedDragons = this.dragons
            this.searchDragons()
        },
        data() {
            return {
                list: [],
                page: 1,
                show: 9,
                searchElements: [],
                rarity: '',
                searchName: '',
                processedDragons: [],
                rarities: [
                    "C", "E", "H", "L", "R", "V"
                ],
                totalPage: 178,
                rankedDragons: [],
            }
        },
        watch: {
            page() {
                this.searchDragons()
            },
            searchName() {
                this.loadDragons()
            },
            rarity() {
                this.loadDragons()
            },
            show() {
                this.calculateTotalPage()
                this.loadDragons()
            }
        },
        mounted() {
            if (this.isRanked || this.isTop) {
                this.loadDragons()
            }

            setTimeout(() => {
                const adsbygoogle = window.adsbygoogle || []
                adsbygoogle.push({});
            }, 3000)
        },
        methods: {
            loadDragons() {
                this.processedDragons = []
                this.page = 1

                this.dragons.map(drg => {
                    let dragonName  = drg.name.toLowerCase().replace(' dragon', '')
                    let searchName  = this.searchName.toLowerCase().replace(' dragon', '')                    
                    let findByName = (searchName.length && dragonName.indexOf(searchName) > -1) || !searchName
                    let findByEle  = this.findByElement(drg)
                    let findByRarity = this.findByRarity(drg)

                    if (findByName && findByEle && findByRarity && dragonName.length) {
                        drg.globalRank = drg.rank.globalRank
                        this.processedDragons.push(drg)
                    }
                })

                if (this.isRanked) {
                    const sorted = this.sortByKey(this.processedDragons, 'globalRank', 'asc')
                    this.processedDragons = sorted
                    this.rankedDragons = !this.rankedDragons.length ? sorted : this.rankedDragons
                }

                if (this.isTop) {
                    const sorted = this.sortByKey(this.processedDragons, 'maxDamage', 'desc')
                    this.processedDragons = sorted
                }

                this.calculateTotalPage()
                this.searchDragons()
            },
            findByRarity(dragon) {
                if (!this.rarity.length) {
                    return true
                }

                return dragon.rarity == this.rarity
            },
            findByElement(dragon) {
                if (!this.searchElements.length) {
                    return true
                }

                let foundDragon = 0

                let el1 = dragon.elements[0] || ''
                let el2 = dragon.elements[1] || ''
                let el3 = dragon.elements[2] || ''
                let el4 = dragon.elements[3] || ''
                
                let search = this.searchElements

                if (el1.length && search.indexOf(el1) > -1) {
                    foundDragon++
                }

                if (el2.length && search.indexOf(el2) > -1) {
                    foundDragon++
                }

                if (el3.length && search.indexOf(el3) > -1) {
                    foundDragon++
                }

                if (el4.length && search.indexOf(el4) > -1) {
                    foundDragon++
                }
                
                return foundDragon == this.searchElements.length
            },
            sortByKey(array, key, order) {
                return array.sort((a, b) => {
                    let x = a[key];
                    let y = b[key];
                    
                    if (order == 'desc') {
                        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
                    }

                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                });
            },
            searchDragons() {
                let start = (this.page - 1) * parseInt(this.show)
                let total = parseInt(start) + parseInt(this.show)
                this.list = []

                for(let i = start; i < total; i++) {
                    if (!this.processedDragons[i]) {
                        break;
                    }
                    this.list.push(this.processedDragons[i])
                }
            },
            paging(page) {
                if (page < 1) {
                    page = 1
                }

                this.page = page
            },
            convertSecondsToTimeString(seconds) {
                var days = Math.floor(seconds / 86400);
                seconds = seconds % 86400;
                var hours = Math.floor(seconds / 3600);
                seconds = seconds % 3600;
                var minutes = Math.floor(seconds / 60);
                seconds = Math.round(seconds % 60);

                var durationPieces = [
                    days + 'd',
                    hours + 'h',
                    minutes + 'm',
                    seconds + 's',
                ].filter(piece => {
                    return piece[0] > 0;
                });

                return durationPieces.join(' ');
            },
            getAvatar(dragon, pos) {
                let name = dragon.image.replace('dragons/', '')
                return `/images/dragons/avatar/${name}_${pos - 1}.png`
            },
            searchElement(ele) {
                if (!this.searchElements.includes(ele)) {
                    this.searchElements.push(ele)
                } else {
                    this.searchElements.splice(this.searchElements.indexOf(ele), 1)
                }
                this.loadDragons()
            },
            getErrorImg(event) {
                event.target.src = `/images/dragons/avatar/desus.png`
            },
            searchRarity(type) {
                this.rarity = this.rarity != type ? type : ''
            },
            calculateTotalPage() {
                let show = (this.processedDragons.length >= this.show) ? this.show : this.processedDragons.length

                this.totalPage = Math.ceil(this.processedDragons.length / show)
            }
        }
    }
</script>